import Form from './form';
import Validate from './validate';
import Fields from './fields';

const Forms = {
    Fields,
    Form,
    Validate
};

export default Forms;