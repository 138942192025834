/**Получить высоту документа */
function getDocumentHeight() {
    const scrollHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight,
    );

    return scrollHeight;
}

export default getDocumentHeight;